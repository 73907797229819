<template>
  <section class="expertises section-padding">
    <div class="add-padding">
      <h2 class="titre-section ">
        <span class="span-background-blue mb-3">
          notre équipe
        </span>
      </h2>
      <div class="bloctitleText text-center mb-3">
        Notre équipe pluridisciplinaire qui s’appuie sur des expertises techniques variées : <br />psychologues,
        juristes,
        responsables RH, coachs, formateurs… met l’humain au centre de ses préoccupations.
      </div>
      <div class="wrap" v-if="isLoading">
        <div class="spinner-wrap">
          <div class="spinner">
            <i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i>
          </div>
        </div>
      </div>
      <div class="slider-wrapper">
        <div class="portfolio_slider_two">
          <swiper ref="mySwiper" :slides-per-view="swiperPerView"
            :autoplay="{ delay: 3000, disableOnInteraction: true }">
            <swiper-slide v-for="item in equipes" :key="item.idA">
              <div class="item">
                <div class="card-block">
                  <img :src='imageUrl(item.idA, item.siteIdA, item.pictureThumbA)' :alt="item.titleSubA"
                    v-if="item.pictureThumbA.length > 0">
                  <img src='/images/icon/person_icon.png' :alt="item.titleSubA" v-else>
                  <div class="bodyCard hover tran3s d-flex flex-column">
                    <span class="title">{{ item.titleA }}</span>
                    <div class="d-flex justify-content-between align-items-center">
                      <p class="subtitle">{{ item.titleSubA }}</p>
                      <div class="text-end">
                        <a :href="`${item.describeA}`" target="_blank" class="linkedInClass"
                          v-if="item.describeA.length > 0">
                          <i>
                            <svg width="22" height="24" viewBox="0 0 22 24" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M20.0714 1.5C20.8682 1.5 21.5714 2.20312 21.5714 3.04688V21C21.5714 21.8438 20.8682 22.5 20.0714 22.5H2.02448C1.2276 22.5 0.57135 21.8438 0.57135 21V3.04688C0.57135 2.20312 1.2276 1.5 2.02448 1.5H20.0714ZM6.89948 19.5V9.51562H3.80573V19.5H6.89948ZM5.3526 8.10938C6.33698 8.10938 7.13385 7.3125 7.13385 6.32812C7.13385 5.34375 6.33698 4.5 5.3526 4.5C4.32135 4.5 3.52448 5.34375 3.52448 6.32812C3.52448 7.3125 4.32135 8.10938 5.3526 8.10938ZM18.5714 19.5V14.0156C18.5714 11.3438 17.962 9.23438 14.8214 9.23438C13.3214 9.23438 12.2901 10.0781 11.8682 10.875H11.8214V9.51562H8.86823V19.5H11.962V14.5781C11.962 13.2656 12.1964 12 13.837 12C15.4307 12 15.4307 13.5 15.4307 14.625V19.5H18.5714Z"
                                fill="#318EC7" />
                            </svg>
                          </i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <a href="/equipe" class="btn-transparent">
          Toute l'équipe EMCI
          <i class="fa-solid fa-arrow-right"></i>
        </a>
      </div>
    </div>
  </section>
</template>


<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import axios from 'axios';

const isLoading = ref([]);
const domaines = ref([
  {
    id: 1,
    url: 'support',
    nomDomaine: 'Support',
    pIdCat: 69
  },
  {
    id: 2,
    url: 'conseil-rh',
    nomDomaine: 'Conseil RH',
    pIdCat: 70
  },
  {
    id: 3,
    url: 'qualite-vie-conditions-travail',
    nomDomaine: 'Qualité de vie et conditions de travail',
    pIdCat: 71
  },
  {
    id: 4,
    url: 'management',
    nomDomaine: 'Management',
    pIdCat: 72
  },
  {
    id: 5,
    url: 'bilan-competences',
    nomDomaine: 'Bilan de compétences',
    pIdCat: 73
  },
  {
    id: 6,
    url: 'recrutement',
    nomDomaine: 'Recrutement',
    pIdCat: 74
  },
  {
    id: 7,
    url: '/',
    nomDomaine: 'Tous',
    pIdCat: 70
  },
])

const equipes = ref([]);

let swiperPerView = ref(0);

SwiperCore.use([Navigation, Autoplay]);

const updateSwiperPerView = () => {
  if (typeof window !== "undefined") {
    if (window.innerWidth > 300 && window.innerWidth < 800) {
      swiperPerView.value = 2;
    } else if (window.innerWidth > 300 && window.innerWidth < 1025) {
      swiperPerView.value = 3;
    } else {
      swiperPerView.value = 5;
    }
  }
};

onMounted(async () => {
  isLoading.value = true;
  try {
    chargerEquipes();
    isLoading.value = false;
  } catch (error) {
    console.error('Erreur lors du chargement des données :', error);
    isLoading.value = false;
  }

  if (window.innerWidth > 300 && window.innerWidth < 800) {
    swiperPerView.value = 2;
  } else if (window.innerWidth > 300 && window.innerWidth < 1025) {
    swiperPerView.value = 3;
  } else {
    swiperPerView.value = 5;
  }
  window.addEventListener("resize", updateSwiperPerView);
});
onBeforeUnmount(() => {
  window.removeEventListener("resize", updateSwiperPerView);
});

async function chargerEquipes() {
  let allData = [];
  for (const domaine of domaines.value) {
    const response = await axios({
      method: 'post',
      url: 'https://ws2-gbbu02.graphibox.eu/EpArticles.ws',
      data: {
        pKey: 'EmPA5d6sdse5z6ldfjPAEhazozp5ksJEZkzn872',
        pFct: 'GetArticles',
        pIdCat: domaine.pIdCat,
      },
    });

    const updatedData = response.data.map(item => {
      const urlMatch = item.describeA.match(/https?:\/\/[^\s]+</);
      if (urlMatch) {
        const urlMatch1 = urlMatch[0].split('<');
        if (urlMatch1) {
          item.describeA = urlMatch1[0];
        } else {
          item.describeA = urlMatch[0];
        }
      } else {
        item.describeA = "";
      }
      return item;
    });

    allData = [...allData, ...updatedData];
  }

  // Utiliser reduce pour filtrer les doublons basés sur titleA
  const filteredData = Object.values(
    allData.reduce((acc, item) => {
      acc[item.titleA] = item;
      return acc;
    }, {})
  );

  equipes.value = filteredData;
}

</script>

<style scoped>
.linkedInClass {
  min-width: 50px;
  min-height: 50px;
  padding: 15px;
}

.text-end,
.text-end i,
.text-end a {
  z-index: 9999;
}

.titre-section {
  color: var(--accent, #25233E);
  text-align: center;
  font-family: Nunito;
  font-size: 3.125rem;
  /*50 px */
  font-style: normal;
  font-weight: 600;
  line-height: 111.5%;
  letter-spacing: -0.825px;
  text-transform: uppercase;
}


.section-padding {
  padding-bottom: 4rem;
}



/* Active center slide (You can change anything here for cenetr slide)*/


.add-padding {
  padding: 0 1vw;
}

.item {
  border-radius: 0.5625rem;
  background: #FFF;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.08);
}

.card-block img {
  border-radius: 0.5625rem 0.5625rem 0 0;
}

.title {
  color: var(--tertiaire, #318EC7);
  /* h3-sm */
  font-family: Nunito;
  font-size: 1.625rem;
  font-style: normal;
  font-weight: 500;
  line-height: 111.5%;
  /* 32.335px */
  letter-spacing: -0.435px;
  min-height: 4vh;
}

.subtitle {
  line-height: 144%;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
}

.bodyCard {
  padding: 1.275rem 1.5625rem;
  row-gap: 2rem;
  height: 170px;
}

.bloctitleText {
  font-family: Inter;
  font-size: 1.3125rem;
  font-style: normal;
  font-weight: 600;
}

.btn-transparent {
  font-family: Inter;
  /*font-size: 1.25rem;*/
  font-style: normal;
  font-weight: 500;
  transition: all .1s;
}

.btn-transparent:hover {
  color: #fff;
  border-color: transparent;
  background: linear-gradient(93deg, #318EC7 9.92%, #E94B54 52.75%, #FAB74B 88.05%);
}


@media only screen and (max-width: 599px) {
  .span-background {
    white-space: nowrap;
  }

  .section-padding {
    padding-bottom: 0;
    padding-top: 3rem;
  }

  .bloctitleText {
    font-family: Inter;
    font-size: 0.8rem;
    line-height: normal;
  }

  .d-flex.justify-content-center {
    max-width: 80%;
    margin: auto;
    text-align: center;
  }

  .btn-transparent {
    font-size: 14px;
    padding: 8px 12px;
  }

  .fa-solid.fa-arrow-right {
    margin-left: 5px;
  }

  .add-padding {
    padding-bottom: 3rem;
  }

  .title {
    font-family: Inter;
    font-size: 15px;
  }

  .subtitle {
    font-family: Inter;
    font-size: 10px;

  }

  .swiper-wrapper {
    transform: translate3d(-132px, 0px, 0px);
    background: red;
  }

  .swiper-slide,
  .swiper-slide.swiper-slide-active,
  .swiper-slide.swiper-slide-Prev,
  .swiper-slide.swiper-slide-next {
    width: 198px !important;
  }

  .linkedInClass {
    min-width: 10px;
    min-height: 10px;
    padding: 10px;
  }
}

/* Styles pour les écrans de petite taille (S) */
@media only screen and (min-width: 1000px) and (max-width: 1024px) {
  .span-background {
    white-space: nowrap;
  }

  .section-padding {
    padding-bottom: 0;
    padding-top: 3rem;
  }

  .bloctitleText {
    font-family: Inter;
    font-size: 1.1rem;
    line-height: normal;
  }

  .d-flex.justify-content-center {
    max-width: 90%;
    margin: auto;
    text-align: center;
  }

  .btn-transparent {
    padding: 8px 12px;
  }

  .fa-solid.fa-arrow-right {
    margin-left: 5px;
  }

  .add-padding {
    padding-bottom: 3rem;
  }

  .title {
    font-family: Inter;
    font-size: 15px;
    line-height: normal;
  }

  .subtitle {
    font-family: Inter;
    font-size: 12px;

  }

  .swiper-wrapper {
    transform: translate3d(-132px, 0px, 0px);
    background: red;
  }

  .swiper-slide,
  .swiper-slide.swiper-slide-active,
  .swiper-slide.swiper-slide-Prev,
  .swiper-slide.swiper-slide-next {
    width: 198px !important;
  }

  .linkedInClass {
    min-width: 10px;
    min-height: 10px;
    padding: 10px;
  }
}
</style>